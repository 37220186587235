import React from "react";
import { Layout } from "../components";
import styled from "styled-components";
import { animated, Spring, config } from "react-spring";

import MichelSteeger from "../../static/michael1.png";

const TextWrapper = styled.div`
  padding: 2rem 1.5rem;
  max-width: 55rem;
  margin: 0 auto;
  margin-bottom: 1rem;

  p {
    font-size: 18px;
  }

  img {
    max-width: 100%;
    margin-bottom: 1.5rem;
  }
`;

const AboutPage = () => (
  <Layout>
    <Spring
      native
      config={config.slow}
      delay={750}
      from={{ opacity: 0, transform: "translate3d(0, -30px, 0)" }}
      to={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
    >
      {(props) => (
        <animated.div style={props}>
          <TextWrapper>
            <h1>
              <span style={{ color: "#ff9e30" }}>Michael Steeger</span>
              <br />
              Büro für Grafikdesign und kreative Markenideen
            </h1>

            <blockquote>
              Ausgebildet als Diplom-Grafikdesigner mit Schwerpunkt Corporate
              Design und Editorial Design. In der weiteren Karriere jede Menge
              Erfahrungen gesammelt bei einigen der erfolgreichsten
              Kreativagenturen Deutschlands (DDB, JungvMatt, Rempen & Partner)
              und dabei auch verschiedene Kreativpreise gewonnen. Danach die
              hohe Schule der nachhaltigen Markenkommunikation bei der Agentur
              Stöhr in Düsseldorf erlernt. Seit 2016 als freier Creative
              Director, Grafikdesigner und Texter tätig.
            </blockquote>

            <img src={MichelSteeger} alt='Michael Steeger' />

            <p>
              Und falls Sie doch mal das große Rad drehen wollen – in meinem
              Netzwerk sind viele gute Leute versammelt: Texter mit Hirn,
              Grafiker mit Seele und umgekehrt. Und natürlich Programmierer –
              mit Sinn für perfekte Technik. Kurz: Es soll Ihnen an nichts
              fehlen.
            </p>

            <p>
              Warum Sie ausgerechnet mit mir zusammenarbeiten sollten? Weil die
              Kombination aus gut überlegen, gut gestalten und gut formulieren
              in einer Person schwer zu finden ist. Zusätzlicher Vorteil: Sie
              haben immer nur einen und immer den richtigen Ansprechpartner.
            </p>

            <p>
              Was mich ausmacht? Denken Sie einfach an die kleinste Marken- und
              Kommunikationsmanufaktur der Welt: gut, schnell und immer
              konzentriert bei Ihrer Sache.
            </p>

            <p>
              Schreiben Sie mir gerne eine E-Mail an{" "}
              <a href='mailto:michaelsteeger@yahoo.com'>
                michaelsteeger@yahoo.com
              </a>{" "}
              oder rufen Sie mich an unter:{" "}
              <a href='tel:+4901637913347'>0163 791 33 47</a>
            </p>

            <p>
              <strong>Ich freue mich auf Ihre Anfrage!</strong>
            </p>

            <h3>Agenturreferenzen:</h3>
            <p>
              BMZ!FCA, Butter, DDB, Grey, JungPro, JungvMatt, Markenmut, Ogilvy
              & Mather, Red Cell, Rempen & Partner, Sixpack, Stöhr
              Markenkommunikation
            </p>

            <h3>Kundenreferenzen</h3>
            <p>
              Air Berlin, Airport Düsseldorf, Apollo Optik, ARAG, Asics,
              Barmenia, Bayer, Böckmann Fahrzeuge, DEG, Dr. Beckmann, Easyways,
              Eckes-Granini, Enyce, Fit, Freshcells, Galeria Kaufhof, GEFA,
              Henkel, Klosterfrau, Kuschelweich, Leifheit, Licher, Marriott,
              Maxdata, Mercedes me, Merkur Win, Metro Group, Neckermann Reisen,
              Nokia, Ökoworld, Pattex, Peakwork, Perwoll, Pioneer, Porsche, REWE
              Reisen, Robinson Club, Roland, RWE, Schäfer Shop, Sega, SG
              Equipment Finance, Spee, Sunil, Thomas Cook, Toshiba, Tour Vital,
              Toyota, VOX, VW, Wasa, Wicküler, Zerone
            </p>
          </TextWrapper>
        </animated.div>
      )}
    </Spring>
  </Layout>
);

export default AboutPage;
